import { orderBy } from 'lodash';
import dateLib from '@wix/communities-blog-client-common/dist/src/helpers/date-lib-wrapper';

export const buildArchiveLinks = (postStatsByDate, newestFirst = true, limit) => {
  postStatsByDate = orderBy(postStatsByDate, ['periodStart'], 'desc');
  if (limit) {
    postStatsByDate = postStatsByDate.slice(0, limit);
  }
  if (!newestFirst) {
    postStatsByDate = orderBy(postStatsByDate, ['periodStart'], 'asc');
  }
  const links = postStatsByDate.map(({ periodStart, publicationsCount }) => {
    const date = dateLib(periodStart).utc();
    return {
      path: `/archive/${date.format('YYYY/MM')}`,
      periodStart,
      postCount: publicationsCount,
      key: date.format('YYYY/MM'),
    };
  });
  return links;
};
